<template>
  <div class="footer">
    <div class="footerMain">
      <div class="footerTop">
        <div class="item">
          <div class="ewmFlex">
            <div class="ewm">
              <img class="ewmImg" src="@/assets/common/gzh.jpg" alt="" />
              <p class="text">法治时代公众号</p>
            </div>
            <div class="ewm">
              <img class="ewmImg" src="@/assets/common/fwh.jpg" alt="" />
              <p class="text">微信服务号</p>
            </div>
          </div>
        </div>
        <div class="footerLink">
          <span>友情链接:</span>
          <a target="_blank" href="http://www.npcpub.com/"
            >中国民主法制出版社</a
          >
          <a target="_blank" href="http://www.cnpubg.com/">中国出版集团公司</a>
          <a target="_blank" href="http://iolaw.cssn.cn/"
            >中国社会科学院法学研究所</a
          >
          <a target="_blank" href="http://renrenlv.cn/">人人律科技集团</a>
          <a target="_blank" href="http://www.faxuanyun.com/">法宣在线</a>
        </div>
      </div>
      <div class="info">
        <span>©2024 法治时代杂志版权所有</span>
        <div>
          <span><a href="https://beian.miit.gov.cn/" target="_blank">京ICP备12049208号-5</a></span>
          <span>
            <a href="" target="_blank">《用户服务协议》</a>
            和<a href="https://www.fzsd124.com/index/index/about/id/157.html" target="_blank">《版权声明》</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalFooter",
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  height: 326px;
  background: #030b1f;
  .footerMain {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .footerTop {
      width: 1200px;
      height: 265px;
      margin: 0 auto;
      padding-top: 48px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .item {
        margin-bottom: 48px;

        .ewmFlex {
          width: 348px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .ewm {
            display: flex;
            align-items: center;
            flex-direction: column;

            .ewmImg {
              width: 100px;
              height: 100px;
              margin-bottom: 12px;
            }

            .text {
              font-size: 14px;
              color: #ffffff;
              line-height: 20px;
              margin-bottom: 0;
            }
          }
        }
      }

      .footerLink {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;

        span {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
        }

        a {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          margin-left: 20px;
        }

        a:hover {
          color: #ffffff;
        }
      }
    }

    .info {
      width: 100%;
      height: 60px;
      border-top: 1px solid rgba(229, 229, 229, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        margin-right: 20px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
        line-height: 20px;

        a {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
</style>