import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})
Vue.filter('day', function (dataStr, pattern = 'YYYY-MM-DD') {
  return moment(dataStr).format(pattern)
})
Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})
// 日期过滤器YYYY-MM-DD HH:mm:ss
Vue.filter('dateTime', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  if (dataStr === undefined || dataStr === '') {
    return ''
  }
  return moment(dataStr).format(pattern)
})

// 手机号掩码
Vue.filter('phoneMaskFilter', function (phone) {
  if (phone === 'undefined' || phone === undefined || phone === '') {
    return ''
  }
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
})


Vue.filter('openTimeFilter', function (value) {
  if (!value) {
    return ''
  }
  const arr=value.split(' ')
  const time=['工作日','包含非工作日']
  return time[arr[0]]+' '+arr[1]+ '-'+arr[2]
})