<template>
  <div>
    <div class="header">
      <div class="headerMain">
        <div class="leftText">
          <span>中国出版传媒股份有限公司主管</span>
          <span>中国民主法制出版社有限公司主办</span>
        </div>
        <div class="rightText" @click="handleOpen">
          <img src="@/assets/common/h-back.png" alt="" />
          法治时代杂志官网
        </div>
      </div>
    </div>
    <div class="navbar">
      <div class="navbarMain">
        <img
          class="logo"
          v-if="isShowNavRouter"
          src="@/assets/common/logo.png"
          alt=""
          width="257"
		  @click="gotoHome"
		  style="cursor: pointer;"
        />
        <img class="logo" v-else src="@/assets/common/logo.png" alt="" width="257" @click="gotoHome" style="cursor: pointer;"/>
        <div class="navLink" v-if="isShowNavRouter">
          <router-link :to="{ name: 'home' }">首页</router-link>
          <router-link :to="{ name: 'ask' }">问律师</router-link>
          <router-link :to="{ name: 'lawyer' }">找律师</router-link>
          <router-link :to="{ name: 'lawOffice' }">找律所</router-link>
          <router-link :to="{ name: 'publicity' }" target='_blank'>律师认证</router-link>
        </div>
        <div class="navLink" v-if="pubNavBar">
          <router-link :to="{ name: 'publicity' }" >律师律所认证</router-link>
          <router-link :to="{ name: 'equity' }">认证权益</router-link>
          <a   @click="isAuthUser">我的主页</a>
        </div>
        <div class="barRight" v-if="!isLogin">
          <div class="enterBtn" @click="login" v-if="this.$route.path !== '/'">
            登录
          </div>
          <div class="enterBtn" @click="login" v-else>登录</div>
        </div>

        <!-- 用户登录后 -->
        <a-dropdown v-if="ident == 0 && isLogin">
          <div class="user-drop">
            <img class="userIcon" src="@/assets/common/user.png" alt="" />
            <span class="name">我的</span>
            <a-icon type="down" />
          </div>
          <div slot="overlay" class="myDropMenu">
            <router-link :to="{ path: '/myAsk' }" v-if="isAuthMenu()">
              <img class="icon" src="@/assets/common/my-tw.png" alt="" />
              <img class="iconA" src="@/assets/common/my-tw-a.png" alt="" />
              我的提问
            </router-link>
            <router-link :to="{ path: '/mySetting' }" v-if="isAuthMenu()">
              <img class="icon" src="@/assets/common/my-set.png" alt="" />
              <img class="iconA" src="@/assets/common/my-set-a.png" alt="" />
              账号设置
            </router-link>
            <a @click="logout">
              <img class="icon" src="@/assets/common/my-out.png" alt="" />
              <img class="iconA" src="@/assets/common/my-out-a.png" alt="" />
              退出
            </a>
          </div>
        </a-dropdown>
        <!-- 律师登录后 -->
        <a-dropdown v-if="ident == 1 && isLogin">
          <div class="user-drop">
            <span class="name">{{ user.userName }}</span>
            <div class="auth" v-if="user.state === 1">
              <img src="@/assets/common/rz.png" alt="" />
              认证律师
            </div>
            <div class="auth" v-else-if="user.state === 0" style="background: #d70315; width: 44px">
              审核中
            </div>
            <div class="auth" v-else-if="user.state === 4" style="background: #d70315; width: 44px">
              已驳回
            </div>
            <div class="auth" v-else style="background: #d70315; width: 44px">
              未认证
            </div>
            <a-icon type="down" />
          </div>
          <a-menu
            slot="overlay"
            :default-selected-keys="defaultSelectedKeys"
            :selectedKeys="defaultSelectedKeys"
            @click="handleClick"
            class="dropMenu"
          >
            <a-menu-item v-for="nav in navList" :key="nav.path">
              <router-link :to="{ path: nav.path }">
                <img class="navIcon" :src="nav.navIcon" alt="" />
                <img class="navIconActive" :src="nav.navIconA" alt="" />
                {{ nav.name }}
              </router-link>
            </a-menu-item>
            <a-menu-item @click="logout">
              <a>
                <img class="navIcon" src="@/assets/common/my-out.png" alt="" />
                <img
                  class="navIconActive"
                  src="@/assets/common/my-out-a.png"
                  alt=""
                />
                退出
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>

        <!-- 律所登录后 -->
        <a-dropdown v-if="ident == 2 && isLogin">
          <div class="user-drop">
            <span class="name">{{ team.name }}</span>
            <div
              class="auth"
              v-if="team.state === 1"
              style="
                background-image: linear-gradient(
                  109deg,
                  #ffe900 0%,
                  #e69e00 100%
                );
              "
            >
              <img src="@/assets/common/rz.png" alt="" />
              认证律所
            </div>
            <div class="auth" v-else-if="team.state === 0" style="background: #d70315; width: 44px">
              审核中
            </div>
            <div class="auth" v-else-if="team.state === 4" style="background: #d70315; width: 44px">
              已驳回
            </div>
            <div class="auth" v-else style="background: #d70315; width: 44px">
              未认证
            </div>
          </div>
          <a-menu
            slot="overlay"
            :default-selected-keys="defaultSelectedKeys"
            :selectedKeys="defaultSelectedKeys"
            @click="handleClick"
            class="dropMenu"
          >
            <a-menu-item v-for="nav in teamNavList" :key="nav.path">
              <router-link :to="{ path: nav.path }">
                <img class="navIcon" :src="nav.navIcon" alt="" />
                <img class="navIconActive" :src="nav.navIconA" alt="" />
                {{ nav.name }}
              </router-link>
            </a-menu-item>
            <a-menu-item @click="logout">
              <a>
                <img class="navIcon" src="@/assets/common/my-out.png" alt="" />
                <img
                  class="navIconActive"
                  src="@/assets/common/my-out-a.png"
                  alt=""
                />
                退出
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>

    <a-modal
      v-model="visibleWX"
      title="微信扫一扫"
      :footer="null"
      :closable="false"
      :maskClosable="false"
    >
      <div style="text-align: center">
        <div class="loadBox" v-if="wxauthQRCodeImg === ''">
          <a-icon type="loading" style="font-size: 40px" />
        </div>
        <img
          v-else
          class="tabImg"
          :src="wxauthQRCodeImg"
          style="width: 200px; height: 200px"
        />
      </div>
      <a-alert
        style="text-align: center; margin-top: 20px"
        message="微信扫一扫关注“法治时代”公众号"
        type="warning"
      />
    </a-modal>
  </div>
</template>

<script>
import { wxMpQrCode, wxQCodeLogin, getInfo } from "@/api/login";
import responseCode from "@/utils/responseCode";
import util from "@/utils/util";
export default {
  name: "navBar",
  data() {
    return {
      wxtimer: null,
      spinning: false,
      wxauthQRCodeImg: "",
      visibleWX: false,
      info: {},
      isLogin: false,
      ident: null,
      user: {},
      team: {},
      navList: [
        {
          path: "/lawInfo",
          navIcon: require("@/assets/my/nav-rz.png"),
          navIconA: require("@/assets/my/nav-rz-a.png"),
          name: "我的认证",
        },
        {
          path: "/order",
          navIcon: require("@/assets/my/nav-dd.png"),
          navIconA: require("@/assets/my/nav-dd-a.png"),
          name: "历史订单",
        },
        {
          path: "/dynamic",
          navIcon: require("@/assets/my/nav-dt.png"),
          navIconA: require("@/assets/my/nav-dt-a.png"),
          name: "发布动态",
        },
        {
          path: "/case",
          navIcon: require("@/assets/my/nav-xs.png"),
          navIconA: require("@/assets/my/nav-xs-a.png"),
          name: "案源线索",
        },
		{
		  path: "/videoClass",
		  navIcon: require("@/assets/my/nav-spkc.png"),
		  navIconA: require("@/assets/my/nav-spkc-a.png"),
		  name: "视频课程",
		},
		{
		  path: "/lstPlatform",
		  navIcon: require("@/assets/my/nav-spkc.png"),
		  navIconA: require("@/assets/my/nav-spkc-a.png"),
		  name: "律数通平台",
		},
        // {
        //   path: "/myHome",
        //   navIcon: require("@/assets/my/nav-mh.png"),
        //   navIconA: require("@/assets/my/nav-mh-a.png"),
        //   name: "我的主页",
        // },
        // {
        //   path: "/equity",
        //   navIcon: require("@/assets/my/nav-qy.png"),
        //   navIconA: require("@/assets/my/nav-qy-a.png"),
        //   name: "我的权益",
        // },
      ],
      teamNavList: [
        {
          path: "/approve",
          navIcon: require("@/assets/my/nav-rz.png"),
          navIconA: require("@/assets/my/nav-rz-a.png"),
          name: "我的认证",
        },
        {
          path: "/order",
          navIcon: require("@/assets/my/nav-dd.png"),
          navIconA: require("@/assets/my/nav-dd-a.png"),
          name: "历史订单",
        },
        {
          path: "/dynamic",
          navIcon: require("@/assets/my/nav-dt.png"),
          navIconA: require("@/assets/my/nav-dt-a.png"),
          name: "发布动态",
        },
        {
          path: "/case",
          navIcon: require("@/assets/my/nav-xs.png"),
          navIconA: require("@/assets/my/nav-xs-a.png"),
          name: "案源线索",
        },
        {
          path: "/aboutLawyer",
          navIcon: require("@/assets/my/nav-ls.png"),
          navIconA: require("@/assets/my/nav-ls-a.png"),
          name: "关联律师",
        },
        {
          path: "/mycode",
          navIcon: require("@/assets/my/nav-dhm.png"),
          navIconA: require("@/assets/my/nav-dhm-a.png"),
          name: "我的激活码",
        },
		{
		  path: "/videoClass",
		  navIcon: require("@/assets/my/nav-spkc.png"),
		  navIconA: require("@/assets/my/nav-spkc-a.png"),
		  name: "视频课程",
		},
		{
		  path: "/lstPlatform",
		  navIcon: require("@/assets/my/nav-lst.png"),
		  navIconA: require("@/assets/my/nav-lst-a.png"),
		  name: "律数通平台",
		},
        // {
        //   path: "/myHome",
        //   navIcon: require("@/assets/my/nav-mh.png"),
        //   navIconA: require("@/assets/my/nav-mh-a.png"),
        //   name: "我的主页",
        // },
        // {
        //   path: "/equity",
        //   navIcon: require("@/assets/my/nav-qy.png"),
        //   navIconA: require("@/assets/my/nav-qy-a.png"),
        //   name: "我的权益",
        // },
      ],
      defaultSelectedKeys: [this.$route.path],
      selectKey: [this.$route.path],
    };
  },
  computed: {
    isShowNavRouter() {
      return (
        this.$route.path === "/" ||
        this.$route.path === "/ask" ||
        this.$route.path === "/lawyer" ||
        this.$route.path === "/lawOffice" ||
        this.$route.path === "/myAsk" ||
        this.$route.path === "/mySetting" 
      );
    },
    pubNavBar(){
      return (
        this.$route.path === "/home" ||
        this.$route.path === "/equity" ||
        this.$route.path === "/approve" ||
        this.$route.path === "/dynamic" ||
        this.$route.path === "/aboutLawyer" ||
        this.$route.path === "/order" ||
        this.$route.path === "/lawInfo" ||
        this.$route.path === "/mycode" ||
        this.$route.path === "/case" ||
        this.$route.path === "/myHome" 
      );
    }
  },
  watch: {
    $route(to, from) {
      this.defaultSelectedKeys = [to.path];
    },
    "$store.getters.info"() {
      this.logoStatus();
    },
  },
  created() {
     //检测登录状态
     this.logoStatus();
  },
  mounted() {
  },
  methods: {
    isAuthUser(){
      if(util.isIdentLawyer()||util.isIdentTeam()){
          this.$router.push({ path: "/myHome" })
       }else{
        util.msg(this,'请先律师律所认证')
       }
    },
    isAuthMenu(){
      const rn = this.$route.name
        if(rn!='lawyerEnter'&&rn!='lawEnter'&&rn!='payOver'&&rn!='publicity'){
          return true
        }
        return false
    },
    logoStatus() {
      const info = this.$store.getters.info;
      if (info.user) {
        this.isLogin = true;
        this.ident = info.user.ident;
        //如果是律师身份，判断是否是律所管理员
        if (info.team && info.user.id == info.team.adminUserId) {
          this.team = info.team;
          this.ident = 2;
        }
        this.user = info.user;
      }
      if(this.ident==1){
        if(info.user.state!=1){
          this.navList.splice(2, 1);
          this.navList.splice(2, 1);
          this.navList.splice(2, 1);
          this.navList.splice(2, 1);
          this.navList.splice(2, 1);
          }
      }
      if(this.ident==2){
        if(info.team&&info.team.state!=1){
          this.teamNavList.splice(2, 1);
          this.teamNavList.splice(2, 1);
          this.teamNavList.splice(2, 1);
          this.teamNavList.splice(2, 1);
          this.teamNavList.splice(2, 1);
          this.teamNavList.splice(2, 1);
          this.teamNavList.splice(2, 1);
        }
      }
    },
    logout() {
      util.confrim(this, "系统提示", "是否确认退出登录？", () => {
        this.$store.dispatch("Logout").then(() => {
          location.href = "/";
        });
      });
    },
    handleClick(e) {
      this.defaultSelectedKeys = [e.key];
    },
    login() {
      if (!this.$store.getters.info.user) {
        this.visibleWX = true;
        this.setwxNotice();
      } else {
        this.loginSuccess("");
      }
    },
    setwxNotice() {
      this.spinning = true;
      const that = this;
      const uuid = util.guid();
      this.$store.dispatch("setWXQRCodeGuid", uuid);
      clearInterval(this.wxtimer);
      wxMpQrCode({
        uuid: uuid,
      }).then((res) => {
        if (res.code === responseCode.success) {
          that.wxauthQRCodeImg = res.data;
          that.spinning = false;
          that.wxtimer = setInterval(that.listenWxqrCode, 2000);
        } else {
          util.error(that, res.msg);
        }
      });
    },
    listenWxqrCode() {
      const uuid = this.$store.getters.getWXWQCodeGuid;
      const that = this;
      wxQCodeLogin({
        uuid: uuid,
      }).then((res) => {
        const result = res.data;
        if (res.code === responseCode.success) {
          clearInterval(this.wxtimer);
          this.visibleWX = false;
          if (result.token) {
            this.$store.dispatch("setWXQRCodeGuid", "");
            this.$store.dispatch("setToken", result.token).then(res=>{
              this.loginSuccess(res);
            });
          }
        } else if (res.code === responseCode.noData) {
          console.log(res.msg);
        } else {
          that.visibleWX = false;
          clearInterval(that.wxtimer);
          util.msg(that, res.msg, () => {
            location = "/";
          });
        }
      });
    },
    loginSuccess(res) {
      this.$store.dispatch("GetInfo").then((res) => {
        location.reload(true)
      });
    },
    handleOpen() {
      var newTab = window.open("about:blank");
      newTab.location.href = "https://www.fzsd124.com/";
    },
	gotoHome() {
	  location = "/";
    },
    onRz(){
      location="/#rz"
    }
  },
};
</script>

<style scoped lang="scss">
@import "./nav.scss";
</style>