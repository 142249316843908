<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <NavBar />
      <div class="content">
        <router-view />
      </div>
      <Footer />
    </div>
  </a-config-provider>
</template>

<script>
import NavBar from "@/components/navBar.vue";
import Footer from "@/components/GlobalFooter.vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>

<style lang="scss">
#app {
  .content {
    width: 100%;
    background: #f3f4f8;
  }
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.clear:before,
.clear:after {
  content: " ";
  display: table;
}

.clear:after {
  clear: both;
}
body::-webkit-scrollbar {
  display: none;
}
.loadBox{
  width: 200px;
  height: 200px;
  border: 1px solid #e5e5e5;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
