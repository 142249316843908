import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
		path: "/home",
		name: "publicity",
		component: () =>
			import("@/views/publicity/index.vue"),
		meta: {
			title: '律师认证',
			hidden: true
		}
	},
	{
		path: "/",
		name: "home",
		component: () =>
			import("@/views/home/index.vue"),
		meta: {
			title: '首页',
			hidden: true
		}
	},
	{
		path: "/ask",
		name: "ask",
		component: () =>
			import("@/views/ask/index.vue"),
		meta: {
			title: '问律师',
			hidden: true
		}
	},
	{
		path: "/lawyer",
		name: "lawyer",
		component: () =>
			import("@/views/lawyer/index.vue"),
		meta: {
			title: '找律师',
			hidden: true
		}
	},
	{
		path: "/caseDetails",
		name: "caseDetails",
		component: () =>
			import("@/views/lawyer/caseDetails.vue"),
		meta: {
			title: '动态详情',
			hidden: true
		}
	},
	{
		path: "/lawyerDetails",
		name: "lawyerDetails",
		component: () =>
			import("@/views/lawyer/details.vue"),
		meta: {
			title: '律师详情',
			hidden: true
		}
	},
	{
		path: "/lawOffice",
		name: "lawOffice",
		component: () =>
			import("@/views/lawOffice/index.vue"),
		meta: {
			title: '找律所',
			hidden: true
		}
	},
	{
		path: "/officeDetails",
		name: "officeDetails",
		component: () =>
			import("@/views/lawOffice/details.vue"),
		meta: {
			title: '律所详情',
			hidden: true
		}
	},

	{
		path: "/myHome",
		name: "myHome",
		component: () =>
			import("@/views/my/myHome.vue"),
		meta: {
			title: '我的主页',
			hidden: true
		}
	},
	{
		path: '/equity',
		name: 'equity',
		component: () => import('@/views/my/equity'),
		meta: {
			title: '认证权益',
			hidden: true
		}
	},
	{
		path: "/lawyerEnter",
		name: "lawyerEnter",
		component: () =>
			import("@/views/join/lawyerEnter.vue"),
		meta: {
			title: '律师认证',
			hidden: true
		}
	},
	{
		path: "/lawEnter",
		name: "lawEnter",
		component: () =>
			import("@/views/join/lawEnter.vue"),
		meta: {
			title: '律所认证',
			hidden: true
		}
	},
	{
		path: "/payOver",
		name: "payOver",
		component: () =>
			import("@/views/join/payOver.vue"),
		meta: {
			title: '认证成功',
			hidden: true
		}
	},
	{
		path: "/user",
		name: "user",
		component: () =>
			import( /* webpackChunkName: "user" */ "@/views/user/index.vue"),
		hidden: true,
		redirect: '/myAsk',
		children: [{
				path: '/myAsk',
				name: 'myAsk',
				component: () => import('@/views/user/myAsk'),
				meta: {
					title: '我的提问',
					hidden: true
				}
			},
			{
				path: '/mySetting',
				name: 'mySetting',
				component: () => import('@/views/user/mySetting'),
				meta: {
					title: '账号设置',
					hidden: true
				}
			}
		]
	},
	{
		path: "/my",
		name: "my",
		component: () =>
			import( /* webpackChunkName: "my" */ "@/views/my/index.vue"),
		hidden: true,
		redirect: '/approve',
		children: [{
				path: '/approve',
				name: 'approve',
				component: () => import('@/views/my/approve'),
				meta: {
					title: '我的认证',
					hidden: true
				}
			},
			{
				path: '/dynamic',
				name: 'dynamic',
				component: () => import('@/views/my/dynamic'),
				meta: {
					title: '发布动态',
					hidden: true
				}
			},
			{
				path: '/aboutLawyer',
				name: 'aboutLawyer',
				component: () => import('@/views/my/aboutLawyer'),
				meta: {
					title: '关联律师',
					hidden: true
				}
			},
			{
				path: '/order',
				name: 'order',
				component: () => import('@/views/my/order'),
				meta: {
					title: '历史订单',
					hidden: true
				}
			},
			{
				path: '/lawInfo',
				name: 'lawInfo',
				component: () => import('@/views/my/lawInfo'),
				meta: {
					title: '律师信息',
					hidden: true
				}
			},
			{
				path: "/pay",
				name: "pay",
				component: () =>
					import("@/views/my/pay.vue"),
			},
			{
				path: "/mycode",
				name: "mycode",
				component: () =>
					import("@/views/my/mycode.vue"),
				meta: {
					title: '我的激活码',
				}
			},
			{
				path: "/case",
				name: "case",
				component: () =>
					import("@/views/my/case.vue"),
				meta: {
					title: '案源线索',
				}
			},
			{
				path: "/videoClass",
				name: "videoClass",
				component: () =>
					import("@/views/my/videoClass.vue"),
				meta: {
					title: '视频课程',
				}
			},
			{
				path: "/video",
				name: "video",
				component: () =>
					import("@/views/my/video.vue"),
				meta: {
					title: '视频课件',
				}
			},
			{
				path: "/lstPlatform",
				name: "lstPlatform",
				beforeEnter(to, from, next) {
					// 在新窗口打开外部链接
					window.open('https://ldt.xaydds.com:8098/register?a=yd625121817', '_blank');
					// 跳转到一个占位组件或者使用 `next(false)` 阻止任何导航
					next(true); // 或者 next(false) 根据需求选择
				}
			}
		]
	},
	{
		path: "/videoView",
		name: "videoView",
		component: () =>
			import("@/views/my/videoView.vue"),
		meta: {
			title: '视频学习',
		}
	}
];

const router = new VueRouter({
	routes,
	mode: 'history',
});

export default router;